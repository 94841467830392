<template>
	<div class="footer">
		<div class="footer-content container">
			<div class="footer-logo">
				<router-link :to="{name: 'loading'}"><img src="~@/assets/images/instantkegs-symbol.png" width="108" height="80" alt="Instant Kegs" /></router-link>
				<small>ALL RIGHTS RESERVED &nbsp; | &nbsp; <a :href="`${contentUrl}/terms/`">PRIVACY &amp; TOS</a></small>
			</div>
			<div class="footer-nav">
				<li class="footer-nav-item">
					<a :href="`${contentUrl}/about-our-kegs/`">Our Kegs</a>
				</li>
				<li class="footer-nav-item">
					<a :href="`${contentUrl}/calculator/`">Keg Calculator</a>
				</li>
				<li class="footer-nav-item">
					<a :href="`${contentUrl}/faq/`">FAQs</a>
				</li>
				<li class="footer-nav-item">
					<a :href="`${contentUrl}/contact-us/`">Contact</a>
				</li>
				<li class="footer-nav-item">
					<a :href="`${contentUrl}/return-policy/`">Return Policy</a>
				</li>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'siteFooter',
	data: () => ({
		urlParsed: true,
		contentUrl: process.env.VUE_APP_CONTENT_SITE_URL,
	}),
}
</script>

<style scoped lang="scss">
.footer {
	background: rgba(40, 40, 40,.86);
	margin-top: 3rem;

	&-content {
		display: flex;
		flex-direction: row;
	}

	&-logo {
		box-sizing: border-box;
		flex: 1 1 0px;
		padding: 38px 0 0;
		color: color_('gray','smoke');
		font-family: $font-family-secondary;
		font-size: 20px;
		line-height: 30px;
		text-transform: uppercase;
		font-weight: 200;

		img {
			margin-right: 20px;
		}

		@media screen and (max-width: map-get($grid-breakpoints,'md')) {
			display: none;
		}
	}

	&-nav {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		list-style-type: none;
		margin: 0;
		padding: 0;

		@media screen and (max-width: map-get($grid-breakpoints,'md')) {
			width: 100%;
			flex-wrap: wrap;
		}

		&-item {
			box-sizing: border-box;
			padding: 60px 16px;

			@media screen and (max-width: map-get($grid-breakpoints,'md')) {
				padding: 20px 16px;
			}

			@media screen and (max-width: map-get($grid-breakpoints,'sm')) {
				padding: 20px 8px;
			}

			a {
				color: color_('gray','smoke');
				font-family: $font-family-secondary;
				font-size: 20px;
				line-height: 30px;
				text-transform: uppercase;
				font-weight: 200;
				transition: color .12s;

				@media screen and (max-width: map-get($grid-breakpoints,'md')) {
					font-size: 14px;
				}

				&:hover,
				&:active,
				&.active {
					text-decoration: none;
					color: color_('brown','light');
				}
			}

			.icon {
				font-size:20px;
				margin-right: 5px;
			}
		}
	}

}
</style>
