<template>
	<div class="header">
		<div class="header-bar">
			<div class="header-content">
				<div class="header-back">
					<a :href="contentUrl"><i class="icon">keyboard_backspace</i> &nbsp; <span>EXIT SHOPPING CART</span></a>
				</div>
				<div class="header-logo">
					<router-link :to="{name: 'step1'}"><img src="~@/assets/images/instantkegs-logo.png" width="274" height="57" alt="Instant Kegs" /></router-link>
				</div>
				<div class="header-total">
					<span>Need Help?</span> &nbsp; <a href="tel:17274999930"> <i class="icon">perm_phone_msg</i> &nbsp; <span>727-499-9930</span></a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'siteHeader',
	data: () => ({
		contentUrl: process.env.VUE_APP_CONTENT_SITE_URL,
	}),
}
</script>

<style scoped lang="scss">
.header {
	margin-bottom: 50px;
	position: relative;
	overflow: hidden;

	@media screen and (max-width: map-get($grid-breakpoints,'sm')) {
		margin-bottom: 25px;
	}

	&-bg {
		// min-height: 100%;
		min-width: 100%;
		position: absolute;
		left: 0;
		top: 0;
		object-fit: cover;
	}

	background:url(../assets/images/bg-header-wizard-2.jpg) no-repeat center;
	background-size: cover;

	&-bar {
		background:rgba(40, 40, 40,.86);
		box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.17);
		position: relative;
		z-index: 100;

		// .view-home & {
		// 	background:rgba(40, 40, 40,0);
		// 	box-shadow: none;
		// 	//padding-top: 20px;
		// }
	}

	&-content {
		display: flex;
		flex-direction: row;
		z-index: 100;

		@media screen and (max-width: map-get($grid-breakpoints,'sm')) {
			//display: block;
		}
	}

	&-logo {
		box-sizing: border-box;
		flex: 3 1 0px;
		padding: 24px 0;
		text-align: center;

		@media screen and (max-width: map-get($grid-breakpoints,'lg')) {
			text-align: center;
			padding: 15px 0;
			flex: 1 1 0px;

			img {
				max-width: 200px;
			}
		}
	}

	&-back {
		//background-color: color_('brown','light');
		color: color_('white');
		font-family: $font-family-secondary;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1 1 0px;
		font-weight: 200;
		letter-spacing: .07em;

		@media screen and (max-width: map-get($grid-breakpoints,'lg')) {
			justify-content: flex-start;
		}

		a {
			display: flex;
			align-items: center;
			color:color_('gray','smoke');
			//font-size: 1.25rem;
			padding-left: 10px;

			.icon {
				font-size: 20px;

				@media screen and (max-width: map-get($grid-breakpoints,'lg')) {
					font-size: 22px;
				}
			}

			&:hover {
				color: color_('brown','light');
			}
		}

		span {
			@media screen and (max-width: map-get($grid-breakpoints,'md')) {
				display: none;
			}
		}
	}

	&-total {
		//background-color: color_('brown','light');
		color: color_('white');
		font-family: $font-family-secondary;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1 1 0px;
		font-weight: 200;
		letter-spacing: .07em;

		@media screen and (max-width: map-get($grid-breakpoints,'lg')) {
			justify-content: flex-end;
		}

		a {
			display: flex;
			align-items: center;
			color:color_('gray','smoke');
			//font-size: 1.25rem;
			letter-spacing: .0em;
			padding-right: 10px;

			.icon {
				font-size: 20px;
			}

			&:hover {
				color: color_('brown','light');
			}
		}

		span {
			@media screen and (max-width: map-get($grid-breakpoints,'md')) {
				display: none;
			}
		}
	}

	&-nav {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		list-style-type: none;
		margin: 0;
		padding: 0;

		@media screen and (max-width: map-get($grid-breakpoints,'sm')) {
			flex: 100%;
			flex-wrap: wrap;
			width: 100%;
		}

		&-item {
			box-sizing: border-box;
			padding: 38px 20px 0;

			@media screen and (max-width: map-get($grid-breakpoints,'lg')) {
				padding: 38px 16px 0;
			}

			@media screen and (max-width: map-get($grid-breakpoints,'sm')) {
				padding: 0px 15px 10px;
				//flex: 1 0 50%;
				text-align: center;

				&:last-child {
					display: none;
				}
			}

			a {
				color:color_('gray','smoke');
				font-family: $font-family-secondary;
				font-size: 20px;
				line-height: 30px;
				text-transform: uppercase;
				font-weight: 200;
				transition: color .12s;

				@media screen and (max-width: map-get($grid-breakpoints,'md')) {
					font-size: 18px;
				}

				@media screen and (max-width: map-get($grid-breakpoints,'md')) {
					font-size: 14px;
				}

				&:hover,
				&:active {
					text-decoration: none;
					color:color_('brown','light');
				}

				&.active {
					color:color_('brown','light');
					text-decoration: none;
				}

				.view-home & {
					color:color_('white');

					&:hover,
					&:active {
						text-decoration: none;
						color:color_('brown','light');
					}
				}
			}

			.icon {
				font-size:20px;
				margin-right: 5px;

				@media screen and (max-width: map-get($grid-breakpoints,'md')) {
					display: none;
				}
			}
		}
	}

	&-text {
		margin:60px auto 0;
		position: relative;
		text-align: center;
		z-index: 100;

		@media screen and (max-width: map-get($grid-breakpoints,'sm')) {
			margin: 30px auto 0;
		}

		&-small {
			text-transform: uppercase;
			font-family: $font-family-secondary;
			font-size: 18px;
			color: color_('white');
			letter-spacing: 5.09px;
			text-shadow: 0px 14px 14px rgba(0,0,0,0.50);
			display: block;
			font-weight: 300;

			@media screen and (max-width: map-get($grid-breakpoints,'sm')) {
				font-size: 16px;
				font-size: 4vw;
			}

			.view-home & {
				font-size: 40px;
				font-size: 2.25vw;
				letter-spacing: 6px;

				@media screen and (max-width: map-get($grid-breakpoints,'sm')) {
					font-size: 18px;
					font-size: 4vw;
				}
			}
		}

		&-large {
			display: block;
			font-family:$font-family-script;
			font-size:120px;
			color: color_('white');
			text-shadow: 0px 14px 14px rgba(0,0,0,0.50);
			text-transform: uppercase;
			letter-spacing: 10.71px;
			padding-bottom: 40px;

			@media screen and (max-width: map-get($grid-breakpoints,'sm')) {
				font-size: 60px;
				font-size: 15vw;
				padding-bottom: 20px;
			}

			.view-home & {
				font-size: 350px;
				font-size: 25vw;
				font-weight: 300;
				letter-spacing: 44.63px;
				letter-spacing: 2.5vw;
				margin-bottom: 60px;

				@media screen and (max-width: map-get($grid-breakpoints,'sm')) {
					font-size: 140px;
					font-size: 30vw;
					margin-bottom: 0;
				}
			}
		}
	}
}
</style>
